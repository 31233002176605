import { Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Box, Button, CircularProgress, Stack } from "@mui/material";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { useToast } from "@src/appV2/lib";
import pluralize from "pluralize";
import { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { useSubmitWorkplaceQuiz } from "../api/useSubmitWorkplaceQuiz/useSubmitWorkplaceQuiz";
import {
  type PostQuizResultResponse,
  type WorkplaceQuizData,
  type WorkplaceQuizResultLogSchema,
  WorkplaceQuizResultStatus,
} from "../types";
import { QuizQuestion } from "./QuizQuestion";
import { QuizSubmissionErrorDialog } from "./QuizSubmissionErrorDialog/QuizSubmissionErrorDialog";

interface QuizFormProps {
  quiz: WorkplaceQuizData;
  onQuizPassed: () => void;
  onQuizFailed: () => void;
  onCancelRetry: () => void;
  onQuizComplete: () => Promise<void>;
}

const quizFormSchema = z.record(z.string(), z.string().min(1));

function getLatestQuizResultLog(
  submitQuizResponse: PostQuizResultResponse
): WorkplaceQuizResultLogSchema | undefined {
  const latestQuizResultLogRelationship =
    submitQuizResponse?.data.relationships?.latestQuizResultLog;
  return submitQuizResponse.included?.find(
    (resource) =>
      isDefined(latestQuizResultLogRelationship) &&
      resource.id === latestQuizResultLogRelationship.data.id &&
      resource.type === latestQuizResultLogRelationship.data.type
  );
}

export function QuizForm(props: QuizFormProps) {
  const { quiz, onQuizComplete, onQuizPassed, onQuizFailed, onCancelRetry } = props;
  const { id: quizId, attributes } = quiz;
  const { passPercentage, questions, workplaceId } = attributes;
  const { mutateAsync: submitQuiz, data: submitQuizResponse } = useSubmitWorkplaceQuiz();
  const showCorrectAnswers = useCbhFlag(
    CbhFeatureFlag.ROLLOUT_SHOW_CORRECT_ANSWERS_ON_QUIZ_SUBMISSION,
    {
      defaultValue: false,
    }
  );
  const { showErrorToast } = useToast();
  const topOfTheFormRef = useRef<HTMLDivElement>(null);
  const questionIdToCorrectOptionIdMap = questions.reduce<Record<string, string>>(
    (questionIdToCorrectOptionIdMapTemporary, question) => {
      const correctOption = question.options.find((option) => option.isCorrect);
      return {
        ...questionIdToCorrectOptionIdMapTemporary,
        ...(isDefined(correctOption) ? { [question.id]: correctOption.id } : {}),
      };
    },
    {}
  );

  const formMethods = useForm({
    // map questions to empty answers
    defaultValues: questions.reduce<Record<string, string>>((emptyAnswerMap, question) => {
      return { ...emptyAnswerMap, [question.id]: "" };
    }, {}),

    resolver: zodResolver(quizFormSchema),
  });

  const { formState, handleSubmit, control } = formMethods;
  const QuizSubmissionErrorDialogState = useModalState();
  const hasUserAlreadySubmittedTheQuiz = formState.isSubmitted;
  const latestQuizResultLog =
    isDefined(submitQuizResponse) && isDefined(submitQuizResponse.data)
      ? getLatestQuizResultLog(submitQuizResponse.data)
      : null;

  function submitAcknowledgementOfIncorrectAnswers(formData: Record<string, string>) {
    const hasUserSelectedAllCorrectOptions = Object.entries(questionIdToCorrectOptionIdMap).every(
      ([questionId, correctOptionId]) => formData[questionId] === correctOptionId
    );
    if (hasUserSelectedAllCorrectOptions) {
      onQuizPassed();
    } else {
      showErrorToast("Please select the correct answer(s) to continue");
    }
  }

  // Creating a function instead of inline so it can be reused while manual retry
  const onFormSubmit = handleSubmit(async (formData) => {
    try {
      if (hasUserAlreadySubmittedTheQuiz) {
        submitAcknowledgementOfIncorrectAnswers(formData);
        return;
      }

      const { data } = await submitQuiz({
        workplaceId,
        quizId,
        answers: formData,
      });
      const quizResult = data.data;
      const quizResultStatus = quizResult.attributes.status;
      const latestQuizResultLogAfterSubmission = getLatestQuizResultLog(data);

      await onQuizComplete();

      if (quizResultStatus === WorkplaceQuizResultStatus.PASSED) {
        if (
          showCorrectAnswers &&
          isDefined(latestQuizResultLogAfterSubmission) &&
          latestQuizResultLogAfterSubmission.attributes.stats.scorePercentage < 1
        ) {
          topOfTheFormRef.current?.scrollIntoView({ behavior: "smooth" });
          return;
        }

        onQuizPassed();
        return;
      }

      if (quizResultStatus === WorkplaceQuizResultStatus.FAILED) {
        onQuizFailed();
        return;
      }
    } catch {
      QuizSubmissionErrorDialogState.openModal();
    }
  });

  const correctAnswersToPass = Math.ceil(passPercentage * questions.length);

  return (
    <FormProvider {...formMethods}>
      <Stack ref={topOfTheFormRef} spacing={3} sx={{ textAlign: "start", flexGrow: 1 }}>
        <Alert
          variant="filled"
          severity={hasUserAlreadySubmittedTheQuiz ? "warning" : "info"}
          sx={{ marginTop: 3 }}
        >
          <Text color="inherit">
            {hasUserAlreadySubmittedTheQuiz
              ? "Select the correct answer(s) to pass the quiz"
              : `Answer at least ${correctAnswersToPass} ${pluralize(
                  "question",
                  correctAnswersToPass
                )} correctly to complete this booking.`}
          </Text>
        </Alert>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", gap: 3, flexGrow: 1 }}
          onSubmit={onFormSubmit}
        >
          <Stack spacing={3}>
            {questions.map((question, index) => (
              <QuizQuestion
                key={question.id}
                control={control}
                name={question.id}
                question={{
                  ...question,
                  text: `${index + 1}. ${question.text}`,
                }}
                quizResult={submitQuizResponse?.data.data.attributes.status}
                userAnswers={
                  latestQuizResultLog?.attributes.responses.reduce((userAnswers, response) => {
                    return {
                      ...userAnswers,
                      [response.questionId]: response.optionId,
                    };
                  }, {}) ?? {}
                }
                questionIdToCorrectOptionIdMap={questionIdToCorrectOptionIdMap}
              />
            ))}
          </Stack>

          {/* marginTop: "auto" is done to align the button at the bottom. Ref: https://hackernoon.com/flexbox-s-best-kept-secret-bd3d892826b6 */}
          <Button
            sx={{
              marginTop: "auto",
            }}
            variant="contained"
            type="submit"
            disabled={formState.isSubmitting || !formState.isValid}
          >
            {formState.isSubmitting ? <CircularProgress color="inherit" /> : "Finish"}
          </Button>
        </Box>
      </Stack>
      <QuizSubmissionErrorDialog
        modalState={QuizSubmissionErrorDialogState}
        onCancel={onCancelRetry}
        onRetry={async () => {
          QuizSubmissionErrorDialogState.closeModal();
          await onFormSubmit();
        }}
      />
    </FormProvider>
  );
}
